<template>
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 float-left w-100">
    <FlashMessage></FlashMessage>

    <!-- HEADER -->
    <Header />

    <div
      v-if="errors && Object.keys(errors).length > 0"
      style="min-height: 20px; background: rgb(255 220 220)"
    >
      <div class="error p-5 text-center">
        <span
          v-for="(err, errIndex) in errors"
          :key="errIndex"
          class="text-danger"
        >
          {{ err[0] }} <br />
        </span>
      </div>
    </div>

    <!-- NAV TAB -->
    <Nav />

    <form v-on:submit.prevent="submit" enctype="multipart/form-data" id="form">
      <!-- COMPANY INFORMATION -->
      <div id="one" class="content float-left w-100 show">
        <CompanyInfo />
      </div>

      <!-- TEAM COMPOSITION -->
      <div id="two" class="content float-left w-100">
        <TeamComposition />
      </div>

      <!-- SOLUTION INFORMATION -->
      <div id="three" class="content float-left w-100">
        <SolutionInformation />
      </div>

      <!-- OPTIONAL CATEGORY -->
      <div
        v-if="slug != 'student-hc-s'"
        id="four"
        class="content float-left w-100"
      >
        <div class="fadein">
          <div class="row">
            <div class="col-md-12">
              <p class="text-center">
                Besides applying with this head category you can also apply for
                two optional categories. Hence for the optional category, you
                need to submit a separate application. You also need to do a
                presentation separately for the optional category.
              </p>
              <div class="col-xs-6 col-lg-6 text-right">
                <h5>Are you interested to apply ?</h5>
              </div>
              <b-form-checkbox
                class="col-form-label-smx col-sm-1 mt-3"
                v-model="data.optional_category"
                name="optional_category"
                value="Yes"
              >
                &nbsp;&nbsp;Yes</b-form-checkbox
              >
              <b-form-checkbox
                class="col-form-label-smx col-sm-1 mt-3"
                v-model="data.optional_category"
                name="optional_category"
                value="No"
              >
                &nbsp;&nbsp;No</b-form-checkbox
              >
            </div>
            <div class="has-error text-center col-sm-12">
              {{ validation.firstError("data.optional_category") }}
            </div>
          </div>

          <fieldset
            v-if="data.optional_category == 'Yes'"
            class="border border-success p-3 rounded mt-5"
          >
            <legend class="border border-success p-2 rounded w-100 text-center">
              Select the optional category
            </legend>

            <!-- CROSS CATEGORY -->
            <div class="col-md-6 col-sm-6">
              <CrossCategory />
            </div>

            <!-- TECHNOLOGY CATEGORY -->
            <div class="col-md-6 col-sm-6">
              <TechnologyCategory />
            </div>
          </fieldset>
        </div>

        <!-- BUTTON SECTION -->
        <div class="form-btn-position row">
          <div class="col-md-6 col-sd-6 back-btn">
            <a class="btn btn-primary select-tab" sl="3">BACK</a>
          </div>

          <div class="col-md-6 col-sd-6 next-btn text-right">
            <!-- <button
              type="button"
              @click="saveAsDraft()"
              class="btn btn-success pr-5 pl-5"
            >
              NEXT
            </button> -->

            <button
              type="button"
              class="btn btn-success pr-5 pl-5 select-tab"
              sl="5"
            >
              NEXT
            </button>
          </div>
        </div>
      </div>

      <!-- Authorization -->
      <div id="five" class="content float-left w-100">
        <Authorization />
      </div>
    </form>
    <!-- <pre>
      {{ data }}
    </pre> -->
  </div>
</template>

<script>
import Promise from "bluebird";
import Nav from "./form/Nav";
import Header from "./form/Header";
import Authorization from "./form/Authorization";
import CompanyInfo from "./form/CompanyInfo";
import SolutionInformation from "./form/SolutionInformation";
import CrossCategory from "./form/CrossCategory";
import TeamComposition from "./form/TeamComposition";
import TechnologyCategory from "./form/TechnologyCategory";

export default {
  data() {
    return {
      cross_categories: JSON.parse(this.cross_obj),
      technology_categories: JSON.parse(this.technology_obj),
      categories: JSON.parse(this.categories_obj),
      sub_categories: JSON.parse(this.sub_categories_obj),
      districts: JSON.parse(this.districts_obj),
      slugName: this.slug,
      edit: false,
      draftSpinner: false,
      submitSpinner: false,
      emailVarify: false,
      data: {
        team_composition: [
          { name: "", designation: "", role: "", email: "" },
          { name: "", designation: "", role: "", email: "" },
        ],
        supervisors: [{ name: "", email: "" }],
        description: {},
        cross_sub_category: "",
        technology_sub_category: "",
        cross_startup_description: {},
        cross_research_description: {},
        technology_blockchain_description: {},
        technology_bigdata_description: {},
        economy: "Bangladesh",
        district: "",
        sub_category_id: "",
        project_type: "",
        student_sub_category_id: "",
        optional_category: "",
        cross_category: 0,
        technology_category: 0,
      },
      countArr: 0,
      errors: {},
      array_errors: {},
      team_errors: {},
      supervisor_errors: {},
    };
  },
  methods: {
    saveAsDraft: function () {
      if (!this.data.email) {
        this.notification("Email field is required", "error");
        return false;
      }
      this.draftSpinner = true;
      this.errors = {};
      var form = document.getElementById("form");
      var formData = new FormData(form);
      formData.append("category_id", this.slug);
      formData.append("type", "draft");

      let url = "form-store";
      if (Object.keys(this.apply_obj).length > 0) {
        url = "form-update";
      }
      axios
        .post("/" + url, formData)
        .then((res) => {
          if (res.data.message) {
            this.scrollTop(0, 230);
            if (Object.keys(this.apply_obj).length == 0) {
              this.notification(
                "Save as draft successfully, please check your mail",
                "success"
              );
              window.location.href = "/draft-message";
            } else {
              this.notification("Save as draft successfully", "success");
              location.reload();
            }
          } else if (res.data.error) {
            this.notification(res.data.error, "error");
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.scrollTop(0, 230);
            this.errors = error.response.data.errors || {};
            this.notification(
              "Something went wrong, please try again",
              "error"
            );
          }
        })
        .then((alw) => setTimeout(() => (this.draftSpinner = false), 400));
    },
    submit: function () {
      this.validate();
      this.$validate().then((res) => {
        const error = this.validation.countErrors();
        // If there is an error
        if (error > 0 || this.countArr > 0) {
          this.notification(
            "You need to fill " + error + " more empty mandatory fields",
            "warning"
          );
          this.scrollTop(0, 230);
          console.log(this.validation.allErrors(), this.array_errors);
          return false;
        }

        // If there is no error
        if (res) {
          this.submitSpinner = true;
          this.errors = {};
          var form = document.getElementById("form");
          var formData = new FormData(form);
          formData.append("type", "submit");
          formData.append("category_id", this.slug);

          let url = "form-store";
          if (Object.keys(this.apply_obj).length > 0) {
            url = "form-update";
          }

          axios
            .post("/" + url, formData)
            .then((res) => {
              if (res.data || res.data.message) {
                this.notification("Submit successfully", "success");
                window.location.href = "/success-message";
              } else if (res.data.error) {
                this.notification(res.data.error, "error");
              }
            })
            .catch((error) => {
              if (error.response.status === 422) {
                this.scrollTop(0, 230);
                this.errors = error.response.data.errors || {};
                this.notification(
                  "Something went wrong, please try again",
                  "error"
                );
              }
            })
            .then((alw) => setTimeout(() => (this.submitSpinner = false), 400));
        }
      });
    },
    validate() {
      let errors = [];
      let teamErrors = [];
      let supvErrors = [];
      if (this.slug != "student-hc-s") {
        this.data.team_composition.forEach((value, key) => {
          let error = [];
          for (var field in value) {
            if (!value[field]) {
              error.push("team." + key + "." + field);
            }
          }
          errors.push(error);
          teamErrors.push(error);
        });
        this.team_errors = teamErrors;
      }

      if (this.slug == "student-hc-s") {
        this.data.team_composition.forEach((value, key) => {
          let error = [];
          for (var field in value) {
            if (!value[field] && field != "designation" && field != "email") {
              error.push("team." + key + "." + field);
            }
          }
          errors.push(error);
          teamErrors.push(error);
        });
        this.team_errors = teamErrors;

        // Supervisors Errors
        this.data.supervisors.forEach((value, key) => {
          let error = [];
          for (var field in value) {
            if (!value[field]) {
              error.push("supervisor." + key + "." + field);
            }
          }
          errors.push(error);
          supvErrors.push(error);
        });
        this.supervisor_errors = supvErrors;
      }

      this.array_errors = errors;
      // store count errors
      this.countArr = 0;
      errors.forEach((value, key) => {
        this.countArr += Object.keys(value).length;
      });
    },
  },
  mounted() {
    if (this.apply_obj && Object.keys(this.apply_obj).length > 0) {
      this.data = JSON.parse(this.apply_obj);
      this.edit = true;
    }
  },
  props: {
    cross_obj: String,
    technology_obj: String,
    categories_obj: String,
    sub_categories_obj: String,
    districts_obj: String,
    apply_obj: String,
    slug: String,
    name: String,
    admin: String,
    days: String,
    active_year: String,
  },
  components: {
    Nav,
    Header,
    Authorization,
    CompanyInfo,
    SolutionInformation,
    CrossCategory,
    TeamComposition,
    TechnologyCategory,
  },
  validators: {
    "data.email": function (value = null) {
      let vm = this;
      return Validator.value(value)
        .required("This field is required")
        .email()
        .maxLength(60)
        .custom(function () {
          if (!Validator.isEmpty(value) && !vm.edit) {
            axios
              .post("/email-validation", {
                email: value,
                slug: vm.slug,
              })
              .then((respons) => {
                if (respons.data.status) {
                  app.emailVarify = true;
                } else {
                  app.emailVarify = false;
                }
              });
            return Promise.delay(1000).then(function () {
              if (app.emailVarify) {
                return "This email already exists";
              } else {
              }
            });
          }
        });
    },
    "data.company_profile": function (value = null) {
      if (this.slugName != "student-hc-s") {
        return Validator.value(value).required("This field is required");
        // .custom(function () {
        //   let length = value ? value.trim().split(/\s+/).length : "";
        //   if (length > 200) {
        //     return "Max 200 words";
        //   }
        // });
      } else {
        return Validator.value(value);
      }
    },
    "data.name_of_organization": function (value = null) {
      return Validator.value(value)
        .required("This field is required")
        .minLength(2)
        .maxLength(255);
    },
    "data.size_of_organization": function (value = null) {
      if (this.slugName != "student-hc-s") {
        return Validator.value(value)
          .required("This field is required")
          .digit()
          .maxLength(11);
      }
    },
    "data.district": function (value = null) {
      return Validator.value(value).required("This field is required");
    },
    "data.name_of_organization_leader": function (value = null) {
      return Validator.value(value)
        .required("This field is required")
        .maxLength(100);
    },
    "data.name_of_contact_person": function (value = null) {
      return Validator.value(value)
        .required("This field is required")
        .maxLength(100);
    },
    "data.telephone_of_contact_person": function (value = null) {
      return Validator.value(value)
        .required("This field is required")
        .digit()
        .maxLength(50);
    },
    "data.email_of_contact_person": function (value = null) {
      return Validator.value(value)
        .required("This field is required")
        .email()
        .maxLength(50);
    },
    "data.solution_name": function (value = null) {
      return Validator.value(value)
        .required("This field is required")
        .maxLength(255);
    },
    "data.date_of_organization": function (value = null) {
      if (this.slugName != "student-hc-s") {
        return Validator.value(value).required("This field is required");
      }
    },
    "data.address": function (value = null) {
      return Validator.value(value).required("This field is required");
    },
    // "data.telephone": function (value = null) {
    //   if (this.slugName != "student-hc-s") {
    //     return Validator.value(value)
    //       .required("This field is required")
    //       .digit()
    //       .maxLength(50);
    //   }
    // },
    "data.website": function (value = null) {
      if (this.slugName != "student-hc-s") {
        return Validator.value(value).required("This field is required");
      }
    },
    "data.email_of_organization_leader": function (value = null) {
      return Validator.value(value).required("This field is required").email();
    },
    "data.designation_of_contact_person": function (value = null) {
      if (this.slugName != "student-hc-s") {
        return Validator.value(value).required("This field is required");
      }
    },
    "data.mobile_of_contact_person": function (value = null) {
      return Validator.value(value)
        .required("This field is required")
        .digit()
        .minLength(11)
        .maxLength(11);
    },
    "data.sub_category_id": function (value = null) {
      if (this.data.sub_category_other) {
        this.data.sub_category_id = "other";
      } else {
        return Validator.value(value).required("This field is required");
      }
    },

    // STUDENT FORM
    "data.economy": function (value = null) {
      if (this.slugName == "student-hc-s") {
        return Validator.value(value)
          .required("This field is required")
          .maxLength(255);
      }
    },
    "data.name_of_department": function (value = null) {
      if (this.slugName == "student-hc-s") {
        return Validator.value(value)
          .required("This field is required")
          .maxLength(100);
      }
    },
    "data.name_of_alternate_contact_person": function (value = null) {
      if (this.slugName == "student-hc-s") {
        return Validator.value(value)
          .required("This field is required")
          .maxLength(100);
      }
    },
    "data.email_of_alternate_contact_person": function (value = null) {
      if (this.slugName == "student-hc-s") {
        return Validator.value(value)
          .required("This field is required")
          .email()
          .maxLength(50);
      }
    },
    "data.mobile_of_alternate_contact_person": function (value = null) {
      if (this.slugName == "student-hc-s") {
        return Validator.value(value)
          .required("This field is required")
          .digit()
          .minLength(11)
          .maxLength(11);
      }
    },
    "data.project_form_date": function (value = null) {
      if (this.slugName == "student-hc-s") {
        return Validator.value(value).required("This field is required");
      }
    },
    "data.project_to_date": function (value = null) {
      if (this.slugName == "student-hc-s") {
        return Validator.value(value).required("This field is required");
      }
    },
    "data.student_sub_category_id": function (value = null) {
      if (this.slugName == "student-hc-s") {
        if (this.data.student_sub_category_other) {
          this.data.student_sub_category_id = "other";
        } else {
          return Validator.value(value).required("This field is required");
        }
      }
    },
    "data.student_sub_category_other": function (value = null) {
      if (
        this.slugName == "student-hc-s" &&
        this.data.student_sub_category_id == "other"
      ) {
        return Validator.value(value).required("This field is required");
      }
    },
    "data.project_type": function (value = null) {
      if (this.slugName == "student-hc-s") {
        return Validator.value(value).required("This field is required");
      }
    },
    "data.description.project_description": function (value = null) {
      if (this.slugName == "student-hc-s") {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 250) {
            return "Max 250 words";
          }
        });
      }
    },
    "data.description.proof_of_concept": function (value = null) {
      if (this.slugName == "student-hc-s") {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      }
    },

    // DESCRIPTION
    "data.description.solution_description": function (value = null) {
      if (this.slugName != "student-hc-s") {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";

          if (length > 250) {
            return "Max 250 words";
          }
        });
      }
    },
    "data.description.uniqueness": function (value = null) {
      return Validator.value(value).required("This field is required")
      .custom(function () {
        let length = value ? value.trim().split(/\s+/).length : "";
        if (length > 350) {
          return "Max 350 words";
        }
      });
    },
    "data.description.functionality_features": function (value = null) {
      return Validator.value(value).required("This field is required")
      .custom(function () {
        let length = value ? value.trim().split(/\s+/).length : "";
        if (length > 350) {
          return "Max 350 words";
        }
      });
    },
    "data.description.quality_application_of_technology": function (
      value = null
    ) {
      return Validator.value(value).required("This field is required")
      .custom(function () {
        let length = value ? value.trim().split(/\s+/).length : "";
        if (length > 350) {
          return "Max 350 words";
        }
      });
    },
    "data.description.declaration": function (value = null) {
      return Validator.value(value).required("This field is required")
      .custom(function () {
        let length = value ? value.trim().split(/\s+/).length : "";
        if (length > 250) {
          return "Max 250 words";
        }
      });
    },
    "data.description.market_potential": function (value = null) {
      if (
        this.slugName == "consumer-hc-c" ||
        this.slugName == "industrial-hc-i" ||
        this.slugName == "business-services-hc-bs"
      ) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      }
    },
    "data.description.value_to_community": function (value = null) {
      if (this.slugName == "inclusions-and-community-services-hc-ics") {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      }
    },
    "data.description.value_to_public_government": function (value = null) {
      if (this.slugName == "public-sactor-and-government-hc-psg") {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      }
    },

    // OPTIONAL CATGOEY
    "data.optional_category": function (value = null) {
      if (this.slugName != "student-hc-s") {
        return Validator.value(value)
          .required("This field is required")
          .custom(function () {
            if (value == false) {
              return "This field is required";
            }
          });
      }
    },
    // CROSS CATEGORY
    "data.cross_sub_category": function (value = null) {
      if (this.data.cross_category) {
        return Validator.value(value).required("This field is required");
      } else {
        return Validator.value(value);
      }
    },
    "data.cross_startup_description.business_model_financials": function (
      value = null
    ) {
      if (this.data.cross_sub_category && this.data.cross_category && this.data.optional_category == 'Yes' && this.data.cross_sub_category == 39) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.cross_startup_description.clientreach": function (value = null) {
      if (this.data.cross_sub_category && this.data.cross_category && this.data.optional_category == 'Yes' && this.data.cross_sub_category == 39) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.cross_startup_description.execution": function (value = null) {
      if (this.data.cross_sub_category && this.data.cross_category && this.data.optional_category == 'Yes' && this.data.cross_sub_category == 39) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.cross_startup_description.investment_viability": function (
      value = null
    ) {
      if (this.data.cross_sub_category && this.data.cross_category && this.data.optional_category == 'Yes' && this.data.cross_sub_category == 39) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.cross_startup_description.unique_selling_proposition": function (
      value = null
    ) {
      if (this.data.cross_sub_category && this.data.cross_category && this.data.optional_category == 'Yes' && this.data.cross_sub_category == 39) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.cross_startup_description.quality_application_of_technology":
      function (value = null) {
        if (
          this.data.cross_sub_category && this.data.cross_category && this.data.optional_category == 'Yes' &&
          this.data.cross_sub_category == 39
        ) {
          return Validator.value(value).required("This field is required")
          .custom(function () {
            let length = value ? value.trim().split(/\s+/).length : "";
            if (length > 350) {
              return "Max 350 words";
            }
          });
        } else {
          return Validator.value(value);
        }
      },
    "data.cross_research_description.uniqueness": function (value = null) {
      if (this.data.cross_sub_category && this.data.cross_category && this.data.optional_category == 'Yes' && this.data.cross_sub_category == 40) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.cross_research_description.proof_concept": function (value = null) {
      if (this.data.cross_sub_category && this.data.cross_category && this.data.optional_category == 'Yes' && this.data.cross_sub_category == 40) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.cross_research_description.functionality_features": function (
      value = null
    ) {
      if (this.data.cross_sub_category && this.data.cross_category && this.data.optional_category == 'Yes' && this.data.cross_sub_category == 40) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.cross_research_description.quality_application_of_technology":
      function (value = null) {
        if (
          this.data.cross_sub_category && this.data.cross_category && this.data.optional_category == 'Yes' &&
          this.data.cross_sub_category == 40
        ) {
          return Validator.value(value).required("This field is required")
          .custom(function () {
            let length = value ? value.trim().split(/\s+/).length : "";
            if (length > 350) {
              return "Max 350 words";
            }
          });
        } else {
          return Validator.value(value);
        }
      },

    // TECHNOLOGY CATEGORY
    "data.technology_sub_category": function (value = null) {
      if (this.data.technology_category) {
        return Validator.value(value).required("This field is required");
      } else {
        return Validator.value(value);
      }
    },
    "data.technology_blockchain_description.value_to_society": function (
      value = null
    ) {
      if (
        this.data.technology_sub_category && this.data.technology_category && this.data.optional_category == 'Yes' &&
        this.data.technology_sub_category == 44
      ) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.technology_blockchain_description.market_potential": function (
      value = null
    ) {
      if (
        this.data.technology_sub_category && this.data.technology_category && this.data.optional_category == 'Yes' &&
        this.data.technology_sub_category == 44
      ) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.technology_blockchain_description.functionality_features": function (
      value = null
    ) {
      if (
        this.data.technology_sub_category && this.data.technology_category && this.data.optional_category == 'Yes' &&
        this.data.technology_sub_category == 44
      ) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.technology_blockchain_description.openness_integration": function (
      value = null
    ) {
      if (
        this.data.technology_sub_category && this.data.technology_category && this.data.optional_category == 'Yes' &&
        this.data.technology_sub_category == 44
      ) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.technology_blockchain_description.quality_application_of_technology":
      function (value = null) {
        if (
          this.data.technology_sub_category && this.data.technology_category && this.data.optional_category == 'Yes' &&
          this.data.technology_sub_category == 44
        ) {
          return Validator.value(value).required("This field is required")
          .custom(function () {
            let length = value ? value.trim().split(/\s+/).length : "";
            if (length > 350) {
              return "Max 350 words";
            }
          });
        } else {
          return Validator.value(value);
        }
      },
    "data.technology_bigdata_description.security": function (value = null) {
      if (
        this.data.technology_sub_category && this.data.technology_category && this.data.optional_category == 'Yes' &&
        this.data.technology_sub_category != 44
      ) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.technology_bigdata_description.creativity": function (value = null) {
      if (
        this.data.technology_sub_category && this.data.technology_category && this.data.optional_category == 'Yes' &&
        this.data.technology_sub_category != 44
      ) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.technology_bigdata_description.scalability": function (value = null) {
      if (
        this.data.technology_sub_category && this.data.technology_category && this.data.optional_category == 'Yes' &&
        this.data.technology_sub_category != 44
      ) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.technology_bigdata_description.openness_integration": function (
      value = null
    ) {
      if (
        this.data.technology_sub_category && this.data.technology_category && this.data.optional_category == 'Yes' &&
        this.data.technology_sub_category != 44
      ) {
        return Validator.value(value).required("This field is required")
        .custom(function () {
          let length = value ? value.trim().split(/\s+/).length : "";
          if (length > 350) {
            return "Max 350 words";
          }
        });
      } else {
        return Validator.value(value);
      }
    },
    "data.technology_bigdata_description.quality_application_of_technology":
      function (value = null) {
        if (
          this.data.technology_sub_category && this.data.technology_category && this.data.optional_category == 'Yes' &&
          this.data.technology_sub_category != 44
        ) {
          return Validator.value(value).required("This field is required")
          .custom(function () {
            let length = value ? value.trim().split(/\s+/).length : "";
            if (length > 350) {
              return "Max 350 words";
            }
          });
        } else {
          return Validator.value(value);
        }
      },

    // image and attachment
    "data.company_logo": function (value = null) {
      if (this.slugName != "student-hc-s" && !this.data.company_logo) {
        return Validator.value(value)
          .required("Logo is required")
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var size = value.size;
              if (size > 2048000) {
                return "Image must be smaller than 2mb";
              }
            }
          })
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var type = value.type;
              if (
                type == "image/jpeg" ||
                type == "image/png" ||
                type == "image/jpg"
              ) {
              } else {
                return "Image must be of type .jpg or .png";
              }
            }
          });
      } else {
        return Validator.value(value);
      }
    },
    "data.declaration_attachment": function (value = null) {

      return Validator.value(value);

      if (
        this.slugName == "student-hc-s" &&
        !this.data.declaration_attachment
      ) {
        return Validator.value(value)
          .required("Attachment is required")
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var size = value.size;
              if (size > 7168000) {
                return "Attachment must be smaller than 7mb";
              }
            }
          })
          .custom(function () {
            if (!Validator.isEmpty(value)) {
              var type = value.type;
              if (type == "application/pdf") {
              } else {
                return "Attachment must be of type .pdf";
              }
            }
          });
      } else {
        return Validator.value(value);
      }
    },
    "data.attachment": function (value = null) {
      return Validator.value(value)
        .required("Attachment is required")
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            let size = value.size;

            console.log('value size 2', value.size);
            if (size > 7168000) {
              return "Attachment must be smaller than 7mb";
            }

            let type = value.type;
            if (type == "application/pdf") {
            } else {
              return "Attachment must be of type .pdf";
            }
          }
        })
    },

    // STEP Authorization
    "data.search_ict_awards": function (value = null) {
      return Validator.value(value)
        .required("This field is required")
        .custom(function () {
          if (value == false) {
            return "This field is required";
          }
        });
    },
    "data.search_ict_awards_other": function (value = null) {
      if (this.data.search_ict_awards == "Other") {
        return Validator.value(value).required("This field is required");
      }
    },
    "data.terms_condition": function (value = null) {
      return Validator.value(value)
        .required("This field is required")
        .custom(function () {
          if (value == false) {
            return "This field is required";
          }
        });
    },
    "data.authorization": function (value = null) {
      return Validator.value(value)
        .required("This field is required")
        .custom(function () {
          if (value == false) {
            return "This field is required";
          }
        });
    },
  },
};
</script>
