var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"fadein team-section"},[(_vm.$parent.slug == 'student-hc-s')?_c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v(" Project Development Period (From Date) ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.project_form_date),expression:"$parent.data.project_form_date"}],staticClass:"form-control",class:{
              'is-invalid': _vm.$parent.validation.hasError(
                'data.project_form_date'
              ),
              'is-valid': _vm.$parent.data.project_form_date,
            },attrs:{"name":"project_form_date","type":"date"},domProps:{"value":(_vm.$parent.data.project_form_date)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "project_form_date", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.project_form_date")))])])]),_vm._v(" "),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v(" Project Development Period (To Date) ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.project_to_date),expression:"$parent.data.project_to_date"}],staticClass:"form-control",class:{
              'is-invalid': _vm.$parent.validation.hasError(
                'data.project_to_date'
              ),
              'is-valid': _vm.$parent.data.project_to_date,
            },attrs:{"name":"project_to_date","type":"date"},domProps:{"value":(_vm.$parent.data.project_to_date)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "project_to_date", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.project_to_date")))])])]),_vm._v(" "),_vm._m(0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pt-3 col-sm-12 mb-3 team-header",staticStyle:{"background":"#ececec"}},[_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"col-sm-2 col-md-2 pl-0"},[_c('label',[_vm._v("Designation")])]):_vm._e(),_vm._v(" "),_vm._m(3),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"col-sm-2 col-md-2 pl-0"},[_c('label',[_vm._v("Email")])]):_vm._e()]),_vm._v(" "),_vm._l((_vm.$parent.data.team_composition),function(team,index1){return _c('div',{key:index1 + 1,staticClass:"col-sm-12"},[_c('div',{staticClass:"col-sm-1 col-md-1 mb-3 pl-0"},[_c('label',{staticClass:"text-center"},[_vm._v(_vm._s(index1 + 1))])]),_vm._v(" "),_c('div',{staticClass:"col-sm-1 col-md-2 pl-0 mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(team.name),expression:"team.name"}],staticClass:"form-control",attrs:{"type":"text","name":'team_composition[' + index1 + '][name]',"placeholder":"Name"},domProps:{"value":(team.name)},on:{"keyup":function($event){return _vm.$parent.validate()},"input":function($event){if($event.target.composing)return;_vm.$set(team, "name", $event.target.value)}}}),_vm._v(" "),(
            _vm.$parent.team_errors[index1] &&
            _vm.$parent.team_errors[index1].includes('team.' + index1 + '.name')
          )?_c('span',{staticClass:"has-error"},[_vm._v("\n          Name is required\n        ")]):_vm._e()]),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"col-sm-1 col-md-2 pl-0 mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(team.designation),expression:"team.designation"}],staticClass:"form-control",attrs:{"type":"text","name":'team_composition[' + index1 + '][designation]',"placeholder":"Designation"},domProps:{"value":(team.designation)},on:{"keyup":function($event){return _vm.$parent.validate()},"input":function($event){if($event.target.composing)return;_vm.$set(team, "designation", $event.target.value)}}}),(
            _vm.$parent.team_errors[index1] &&
            _vm.$parent.team_errors[index1].includes(
              'team.' + index1 + '.designation'
            )
          )?_c('span',{staticClass:"has-error"},[_vm._v("\n          Designation is required\n        ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-sm-3 col-md-3 pl-0 mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(team.role),expression:"team.role"}],staticClass:"form-control",attrs:{"type":"text","name":'team_composition[' + index1 + '][role]',"placeholder":"Role in the Team/Project"},domProps:{"value":(team.role)},on:{"keyup":function($event){return _vm.$parent.validate()},"input":function($event){if($event.target.composing)return;_vm.$set(team, "role", $event.target.value)}}}),(
            _vm.$parent.team_errors[index1] &&
            _vm.$parent.team_errors[index1].includes('team.' + index1 + '.role')
          )?_c('span',{staticClass:"has-error"},[_vm._v("\n          Role is required\n        ")]):_vm._e()]),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"col-sm-2 col-md-2 pl-0 mb-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(team.email),expression:"team.email"}],staticClass:"form-control",attrs:{"type":"text","name":'team_composition[' + index1 + '][email]',"placeholder":"Email"},domProps:{"value":(team.email)},on:{"keyup":function($event){return _vm.$parent.validate()},"input":function($event){if($event.target.composing)return;_vm.$set(team, "email", $event.target.value)}}}),(
            _vm.$parent.team_errors[index1] &&
            _vm.$parent.team_errors[index1].includes('team.' + index1 + '.email')
          )?_c('span',{staticClass:"has-error"},[_vm._v("\n          Email is required\n        ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-sm-12 col-md-2 m-0 pl-0"},[(index1 > 1)?_c('i',{staticClass:"fa fa-times btn btn-danger btn-sm remove-team",on:{"click":function($event){return _vm.$parent.data.team_composition.splice(index1, 1)}}}):_vm._e(),_vm._v(" "),(
            index1 + 1 == Object.keys(_vm.$parent.data.team_composition).length &&
            Object.keys(_vm.$parent.data.team_composition).length < 5
          )?_vm._t("default",function(){return [_c('a',{staticClass:"btn btn-primary btn-sm remove-team",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$parent.data.team_composition.push({
                name: '',
                designation: '',
                role: '',
                email: '',
              })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add More")])]}):_vm._e()],2)])}),_vm._v(" "),_c('hr'),_vm._v(" "),_c('div',{staticClass:"w-100 mt-2"}),_vm._v(" "),(_vm.$parent.slug == 'student-hc-s')?_vm._t("default",function(){return [_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._l((_vm.$parent.data.supervisors),function(supv,index){return _c('div',{key:index + 1 + 'abc',staticClass:"col-sm-12 mb-3"},[_c('div',{staticClass:"col-sm-1 col-md-1 pl-0"},[_c('label',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1))])]),_vm._v(" "),_c('div',{staticClass:"col-sm-5 col-md-2 mb-4 pl-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(supv.name),expression:"supv.name"}],staticClass:"form-control",attrs:{"type":"text","name":'supervisors[' + index + '][name]',"placeholder":"Name"},domProps:{"value":(supv.name)},on:{"keyup":function($event){return _vm.$parent.validate()},"input":function($event){if($event.target.composing)return;_vm.$set(supv, "name", $event.target.value)}}}),_vm._v(" "),(
              _vm.$parent.supervisor_errors[index] &&
              _vm.$parent.supervisor_errors[index].includes(
                'supervisor.' + index + '.name'
              )
            )?_c('span',{staticClass:"has-error"},[_vm._v("\n            Name is required\n          ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-sm-6 col-md-3 mb-4 pl-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(supv.email),expression:"supv.email"}],staticClass:"form-control",attrs:{"type":"text","name":'supervisors[' + index + '][email]',"placeholder":"Email"},domProps:{"value":(supv.email)},on:{"keyup":function($event){return _vm.$parent.validate()},"input":function($event){if($event.target.composing)return;_vm.$set(supv, "email", $event.target.value)}}}),_vm._v(" "),(
              _vm.$parent.supervisor_errors[index] &&
              _vm.$parent.supervisor_errors[index].includes(
                'supervisor.' + index + '.email'
              )
            )?_c('span',{staticClass:"has-error"},[_vm._v("\n            Email is required\n          ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-sm-12 col-md-2 m-0 pl-0"},[(index > 0)?_c('i',{staticClass:"fa fa-times btn btn-danger btn-sm",on:{"click":function($event){return _vm.$parent.data.supervisors.splice(index, 1)}}}):_vm._e(),_vm._v(" "),(
              index + 1 == Object.keys(_vm.$parent.data.supervisors).length &&
              Object.keys(_vm.$parent.data.supervisors).length < 3
            )?_vm._t("default",function(){return [_c('a',{staticClass:"btn btn-primary btn-sm",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$parent.data.supervisors.push({ name: '', email: '' })}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" Add More")])]}):_vm._e()],2)])}),_vm._v(" "),_c('div',{staticClass:"col-sm-12 mb-3"})]}):_vm._e()],2),_vm._v(" "),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 mt-2"},[_c('h4',[_vm._v("Student (s) Information")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-1 col-md-1 mb-3 pl-0"},[_c('label',{staticClass:"text-center"},[_vm._v("SL.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-2 col-md-2 pl-0"},[_c('label',[_vm._v("Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-3 col-md-3 pl-0"},[_c('label',[_vm._v("Role in the Team/Project")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row col-12"},[_c('div',{staticClass:"col-sm-12"},[_c('h4',[_vm._v("Supervisor (s) Information")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 mb-3 p-3 team-header",staticStyle:{"background":"#ececec"}},[_c('div',{staticClass:"col-sm-1 col-md-1 pl-0"},[_c('label',{staticClass:"text-center"},[_vm._v("SL.")])]),_vm._v(" "),_c('div',{staticClass:"col-sm-5 col-md-2 pl-0"},[_c('label',[_vm._v("Name")])]),_vm._v(" "),_c('div',{staticClass:"col-sm-6 col-md-3 pl-0"},[_c('label',[_vm._v("Email")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-btn-position row"},[_c('div',{staticClass:"col-sm-1 col-md-6 col-sm-6 back-btn"},[_c('a',{staticClass:"btn btn-primary select-tab",attrs:{"sl":"1"}},[_vm._v("BACK")])]),_vm._v(" "),_c('div',{staticClass:"col-sm-1 col-md-6 col-sm-6 next-btn text-right"},[_c('button',{staticClass:"btn btn-success pr-5 pl-5 select-tab",attrs:{"type":"button","sl":"3"}},[_vm._v("\n        NEXT\n      ")])])])
}]

export { render, staticRenderFns }