var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fadein"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.email),expression:"$parent.data.email"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError('data.email'),
            'is-valid':
              !_vm.$parent.validation.hasError('data.email') &&
              _vm.$parent.data.email,
          },attrs:{"name":"email","type":"email"},domProps:{"value":(_vm.$parent.data.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "email", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.email")))])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[(_vm.$parent.slug != 'student-hc-s')?_vm._t("default",function(){return [_vm._v("\n            Name of Organization / Individual\n            "),_c('small',{staticClass:"text-muted"},[_vm._v("\n              (This will be printed on your certificates)\n            ")])]}):_vm._t("default",function(){return [_vm._v("Name of School / Institution / University ")]})],2),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.name_of_organization),expression:"$parent.data.name_of_organization"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.name_of_organization'
            ),
            'is-valid': _vm.$parent.data.name_of_organization,
          },attrs:{"name":"name_of_organization","type":"text"},domProps:{"value":(_vm.$parent.data.name_of_organization)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "name_of_organization", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.name_of_organization")))])]),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"form-group"},[_vm._m(1),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.size_of_organization),expression:"$parent.data.size_of_organization"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.size_of_organization'
            ),
            'is-valid': _vm.$parent.data.size_of_organization,
          },attrs:{"name":"size_of_organization","type":"text"},domProps:{"value":(_vm.$parent.data.size_of_organization)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "size_of_organization", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.size_of_organization")))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("District")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.district),expression:"$parent.data.district"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError('data.district'),
            'is-valid': _vm.$parent.data.district,
          },attrs:{"name":"district"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$parent.data, "district", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("---Select One---")]),_vm._v(" "),_vm._l((_vm.$parent.districts),function(value,id){return _c('option',{key:id,domProps:{"value":value.id}},[_vm._v("\n            "+_vm._s(value.name)+"\n          ")])})],2),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.district")))])]),_vm._v(" "),(_vm.$parent.slug == 'student-hc-s')?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("Economy")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.economy),expression:"$parent.data.economy"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError('data.economy'),
            'is-valid': _vm.$parent.data.economy,
          },attrs:{"name":"economy","type":"text"},domProps:{"value":(_vm.$parent.data.economy)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "economy", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.economy")))])]):_vm._e(),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Fax")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.fax),expression:"$parent.data.fax"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError('data.fax'),
            'is-valid': _vm.$parent.data.fax,
          },attrs:{"name":"fax","type":"text"},domProps:{"value":(_vm.$parent.data.fax)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "fax", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.fax")))])]):_vm._e(),_vm._v(" "),(_vm.$parent.slug == 'student-hc-s')?_c('div',{staticClass:"form-group"},[_vm._m(2),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.name_of_department),expression:"$parent.data.name_of_department"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.name_of_department'
            ),
            'is-valid': _vm.$parent.data.name_of_department,
          },attrs:{"name":"name_of_department","type":"text"},domProps:{"value":(_vm.$parent.data.name_of_department)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "name_of_department", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.name_of_department")))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[(_vm.$parent.slug != 'student-hc-s')?_vm._t("default",function(){return [_vm._v("\n            Name of the Organizational Leader\n            "),_c('small',{staticClass:"text-muted"},[_vm._v("(e.g. CEO/MD/GM)")])]}):_vm._t("default",function(){return [_vm._v("Name of Head of School/Institution/University ")]})],2),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.name_of_organization_leader),expression:"$parent.data.name_of_organization_leader"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.name_of_organization_leader'
            ),
            'is-valid': _vm.$parent.data.name_of_organization_leader,
          },attrs:{"name":"name_of_organization_leader","type":"text"},domProps:{"value":(_vm.$parent.data.name_of_organization_leader)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "name_of_organization_leader", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.name_of_organization_leader")))])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("Name of the Contact Person\n          "),(_vm.$parent.slug == 'student-hc-s')?_c('small',[_vm._v("\n            (Preferred a Student)\n          ")]):_vm._e()]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.name_of_contact_person),expression:"$parent.data.name_of_contact_person"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.name_of_contact_person'
            ),
            'is-valid': _vm.$parent.data.name_of_contact_person,
          },attrs:{"name":"name_of_contact_person","type":"text"},domProps:{"value":(_vm.$parent.data.name_of_contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "name_of_contact_person", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.name_of_contact_person")))])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.$parent.slug != 'student-hc-s' ? 'req' : ''},[_vm._v("Telephone of the Contact Person\n        ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.telephone_of_contact_person),expression:"$parent.data.telephone_of_contact_person"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.telephone_of_contact_person'
            ),
            'is-valid': _vm.$parent.data.telephone_of_contact_person,
          },attrs:{"name":"telephone_of_contact_person","type":"text"},domProps:{"value":(_vm.$parent.data.telephone_of_contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "telephone_of_contact_person", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.telephone_of_contact_person")))])]),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("E-mail of the Contact Person ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.email_of_contact_person),expression:"$parent.data.email_of_contact_person"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.email_of_contact_person'
            ),
            'is-valid':
              !_vm.$parent.validation.hasError('data.email_of_contact_person') &&
              _vm.$parent.data.email_of_contact_person,
          },attrs:{"name":"email_of_contact_person","type":"text"},domProps:{"value":(_vm.$parent.data.email_of_contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "email_of_contact_person", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.email_of_contact_person")))])]):_vm._e(),_vm._v(" "),(_vm.$parent.slug == 'student-hc-s')?_vm._t("default",function(){return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("Alternate Contact Person – Name")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.name_of_alternate_contact_person),expression:"$parent.data.name_of_alternate_contact_person"}],staticClass:"form-control",class:{
              'is-invalid': _vm.$parent.validation.hasError(
                'data.name_of_alternate_contact_person'
              ),
              'is-valid': _vm.$parent.data.name_of_alternate_contact_person,
            },attrs:{"name":"name_of_alternate_contact_person","type":"text"},domProps:{"value":(_vm.$parent.data.name_of_alternate_contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "name_of_alternate_contact_person", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
              "data.name_of_alternate_contact_person"
            )))])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.$parent.slug != 'student-hc-s' ? 'req' : ''},[_vm._v("Telephone of the (Alternate Contact Person)")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.telephone_of_alternate_contact_person),expression:"$parent.data.telephone_of_alternate_contact_person"}],staticClass:"form-control",class:{
              'is-invalid': _vm.$parent.validation.hasError(
                'data.telephone_of_alternate_contact_person'
              ),
              'is-valid': _vm.$parent.data.telephone_of_alternate_contact_person,
            },attrs:{"name":"telephone_of_alternate_contact_person","type":"text"},domProps:{"value":(_vm.$parent.data.telephone_of_alternate_contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "telephone_of_alternate_contact_person", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
              "data.telephone_of_alternate_contact_person"
            )))])]),_vm._v(" "),_c('div',{staticClass:"form-group form-logo"},[_c('label',{},[_vm._v("\n            Team Logo "),_c('small',[_vm._v("(width:200px x height:200px)")]),_vm._v(" "),(_vm.$parent.apply_obj && _vm.$parent.data.team_logo)?_c('a',{attrs:{"target":"_blank","href":_vm.$root.asset + '/storage/' + _vm.$parent.data.team_logo}},[_vm._v("\n              View Logo\n            ")]):_vm._e()]),_vm._v(" "),_c('b-form-file',{staticClass:"file1 mb-3",attrs:{"accept":".png, .jpg, .jpeg","id":"file-small","size":"sm","name":"team_logo","drop-placeholder":"Drop file here"},model:{value:(_vm.$parent.data.team_logo),callback:function ($$v) {_vm.$set(_vm.$parent.data, "team_logo", $$v)},expression:"$parent.data.team_logo"}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.team_logo")))])],1)]}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"col-md-6 company-right-section"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[(_vm.$parent.slug != 'student-hc-s')?_vm._t("default",function(){return [_vm._v(" Solution Name ")]}):_vm._t("default",function(){return [_vm._v("Name of the Project ")]})],2),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.solution_name),expression:"$parent.data.solution_name"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError('data.solution_name'),
            'is-valid': _vm.$parent.data.solution_name,
          },attrs:{"name":"solution_name","type":"text"},domProps:{"value":(_vm.$parent.data.solution_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "solution_name", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.solution_name")))])]),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("Date of Establishment of the Organization ( ex: 2021-12-31 )\n        ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.date_of_organization),expression:"$parent.data.date_of_organization"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.date_of_organization'
            ),
            'is-valid': _vm.$parent.data.date_of_organization,
          },attrs:{"name":"date_of_organization","type":"date"},domProps:{"value":(_vm.$parent.data.date_of_organization)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "date_of_organization", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.date_of_organization")))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("Address\n          "),(_vm.$parent.slug == 'student-hc-s')?_vm._t("default",function(){return [_vm._v("Line 1")]}):_vm._e()],2),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.address),expression:"$parent.data.address"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError('data.address'),
            'is-valid': _vm.$parent.data.address,
          },attrs:{"name":"address","type":"text"},domProps:{"value":(_vm.$parent.data.address)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "address", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.address")))])]),_vm._v(" "),(_vm.$parent.slug == 'student-hc-s')?_c('div',{staticClass:"form-group"},[_c('label',{},[_vm._v("Address Line 2")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.address2),expression:"$parent.data.address2"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError('data.address2'),
            'is-valid': _vm.$parent.data.address2,
          },attrs:{"name":"address2","type":"text"},domProps:{"value":(_vm.$parent.data.address2)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "address2", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.address2")))])]):_vm._e(),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"form-group"},[_vm._m(3),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.telephone),expression:"$parent.data.telephone"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError('data.telephone'),
            'is-valid': _vm.$parent.data.telephone,
          },attrs:{"name":"telephone","type":"text"},domProps:{"value":(_vm.$parent.data.telephone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "telephone", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.telephone")))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.$parent.slug != 'student-hc-s' ? 'req' : ''},[_vm._v("Website")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.website),expression:"$parent.data.website"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError('data.website'),
            'is-valid': _vm.$parent.data.website,
          },attrs:{"name":"website","type":"text"},domProps:{"value":(_vm.$parent.data.website)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "website", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.website")))])]),_vm._v(" "),(_vm.$parent.slug == 'student-hc-s')?_c('div',{staticClass:"form-group"},[_vm._m(4),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.telephone_department),expression:"$parent.data.telephone_department"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.telephone_department'
            ),
            'is-valid': _vm.$parent.data.telephone_department,
          },attrs:{"name":"telephone_department","type":"text"},domProps:{"value":(_vm.$parent.data.telephone_department)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "telephone_department", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.telephone_department")))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[(_vm.$parent.slug != 'student-hc-s')?_vm._t("default",function(){return [_vm._v("\n            Email of the Organizational Leader\n          ")]}):_vm._t("default",function(){return [_vm._v("Email of Head of School/Institution/University ")]})],2),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.email_of_organization_leader),expression:"$parent.data.email_of_organization_leader"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.email_of_organization_leader'
            ),
            'is-valid':
              !_vm.$parent.validation.hasError(
                'data.email_of_organization_leader'
              ) && _vm.$parent.data.email_of_organization_leader,
          },attrs:{"name":"email_of_organization_leader","type":"email"},domProps:{"value":(_vm.$parent.data.email_of_organization_leader)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "email_of_organization_leader", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.email_of_organization_leader")))])]),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("Designation of Contact Person ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.designation_of_contact_person),expression:"$parent.data.designation_of_contact_person"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.designation_of_contact_person'
            ),
            'is-valid': _vm.$parent.data.designation_of_contact_person,
          },attrs:{"name":"designation_of_contact_person","type":"text"},domProps:{"value":(_vm.$parent.data.designation_of_contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "designation_of_contact_person", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.designation_of_contact_person")))])]):_vm._e(),_vm._v(" "),(_vm.$parent.slug == 'student-hc-s')?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("E-mail of the Contact Person ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.email_of_contact_person),expression:"$parent.data.email_of_contact_person"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.email_of_contact_person'
            ),
            'is-valid':
              !_vm.$parent.validation.hasError('data.email_of_contact_person') &&
              _vm.$parent.data.email_of_contact_person,
          },attrs:{"name":"email_of_contact_person","type":"text"},domProps:{"value":(_vm.$parent.data.email_of_contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "email_of_contact_person", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.email_of_contact_person")))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("Mobile of the Contact Person ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.mobile_of_contact_person),expression:"$parent.data.mobile_of_contact_person"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.mobile_of_contact_person'
            ),
            'is-valid': _vm.$parent.data.mobile_of_contact_person,
          },attrs:{"name":"mobile_of_contact_person","type":"text"},domProps:{"value":(_vm.$parent.data.mobile_of_contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "mobile_of_contact_person", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.mobile_of_contact_person")))])]),_vm._v(" "),(_vm.$parent.slug == 'student-hc-s')?_vm._t("default",function(){return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("E-mail of the (Alternate Contact Person)")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.email_of_alternate_contact_person),expression:"$parent.data.email_of_alternate_contact_person"}],staticClass:"form-control",class:{
              'is-invalid': _vm.$parent.validation.hasError(
                'data.email_of_alternate_contact_person'
              ),
              'is-valid':
                !_vm.$parent.validation.hasError(
                  'data.email_of_alternate_contact_person'
                ) && _vm.$parent.data.email_of_alternate_contact_person,
            },attrs:{"name":"email_of_alternate_contact_person","type":"text"},domProps:{"value":(_vm.$parent.data.email_of_alternate_contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "email_of_alternate_contact_person", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
              "data.email_of_alternate_contact_person "
            )))])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("Mobile of the (Alternate Contact Person) ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.mobile_of_alternate_contact_person),expression:"$parent.data.mobile_of_alternate_contact_person"}],staticClass:"form-control",class:{
              'is-invalid': _vm.$parent.validation.hasError(
                'data.mobile_of_alternate_contact_person'
              ),
              'is-valid': _vm.$parent.data.mobile_of_alternate_contact_person,
            },attrs:{"name":"mobile_of_alternate_contact_person","type":"text"},domProps:{"value":(_vm.$parent.data.mobile_of_alternate_contact_person)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "mobile_of_alternate_contact_person", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
              "data.mobile_of_alternate_contact_person"
            )))])])]}):_vm._e(),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"form-group form-logo"},[_c('label',[_c('span',{staticClass:"req"},[_vm._v("Company Logo")]),_vm._v(" "),_c('small',[_vm._v("(width:200px x height:200px)")]),_vm._v(" "),(_vm.$parent.apply_obj && _vm.$parent.data.company_logo)?_c('a',{attrs:{"target":"_blank","href":_vm.$root.asset + '/storage/' + _vm.$parent.data.company_logo}},[_vm._v("\n            View Logo\n          ")]):_vm._e()]),_vm._v(" "),_c('b-form-file',{staticClass:"file1 mb-3",attrs:{"accept":".png, .jpg, .jpeg","id":"file-small","size":"sm","name":"company_logo","drop-placeholder":"Drop file here"},model:{value:(_vm.$parent.data.company_logo),callback:function ($$v) {_vm.$set(_vm.$parent.data, "company_logo", $$v)},expression:"$parent.data.company_logo"}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.company_logo")))])],1):_vm._e()],2)]),_vm._v(" "),(_vm.$parent.slug != 'student-hc-s')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mt-20px"},[_c('div',{staticClass:"form-group"},[_vm._m(5),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.company_profile),expression:"$parent.data.company_profile"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError('data.company_profile'),
            'is-valid':
              _vm.$parent.data.company_profile &&
              !_vm.$parent.validation.hasError('data.company_profile'),
          },attrs:{"name":"company_profile"},domProps:{"value":(_vm.$parent.data.company_profile)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data, "company_profile", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.company_profile")))])])])]):_vm._e(),_vm._v(" "),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"req"},[_vm._v("Email Address\n          "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Confirmation mails will be sent to this email address)\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"req"},[_vm._v("Size of the Organization\n          "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Enter the Number of employees in the organization)\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"req"},[_vm._v("Name of Department\n          "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Applicable for Undergraduate category and for Junior & Senior\n            Student Categories type “Student”)\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_vm._v("Telephone "),_c('small',{staticClass:"text-muted"},[_vm._v("(Land Line)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{},[_vm._v("Department Telephone\n          "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (“General Line” for Junior & Senior Students enter the school\n            general number)\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Company Profile ")]),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 200 words) Please fill in short company profile (even if\n            attaching company brochure to this application). Include number of\n            employees, Annual Revenue, Number of Branches, patents owned,\n            number of employee employed in the ICT area in the company.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-btn-position row"},[_c('div',{staticClass:"col-md-12 next-btn text-right"},[_c('button',{staticClass:"btn btn-success pr-5 pl-5 select-tab",attrs:{"type":"button","sl":"2"}},[_vm._v("\n        NEXT\n      ")])])])
}]

export { render, staticRenderFns }