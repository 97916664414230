var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("default"),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.name),expression:"data.name"}],staticClass:"form-control inputBox",class:{
        'is-invalid': _vm.validation.hasError('data.name'),
        'is-valid': _vm.data.name,
      },attrs:{"name":"name","placeholder":"Your Name ...","type":"text"},domProps:{"value":(_vm.data.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "name", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.email),expression:"data.email"}],staticClass:"form-control inputBox",class:{
        'is-invalid': _vm.validation.hasError('data.email'),
        'is-valid': _vm.data.email,
      },attrs:{"name":"email","placeholder":"Your E-mail ...","type":"email"},domProps:{"value":(_vm.data.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "email", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.message),expression:"data.message"}],staticClass:"form-control texareaBox",class:{
        'is-invalid': _vm.validation.hasError('data.message'),
        'is-valid': _vm.data.message,
      },staticStyle:{"color":"#d4dde6"},attrs:{"name":"message","placeholder":"Write your message here.."},domProps:{"value":(_vm.data.message)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "message", $event.target.value)}}})]),_vm._v(" "),_c('button',{staticClass:"btn btn-success btn-block website-query",staticStyle:{"background":"#2260b0","border":"none"},attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[(!_vm.spinner)?_c('span',[_vm._v("SEND")]):_c('span',[_c('i',{staticClass:"fa fa-spinner fa-spin"}),_vm._v(" sending... ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }