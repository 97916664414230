<template>
  <div>
    <!-- FORM -->
    <form v-on:submit.prevent="submit" id="form">
      <div class="col-sm-12">
        <div class="col-sm-12 col-md-12" style="margin-bottom: 15px">
          <div class="lgx-about-content-area">
            <div class="lgx-heading">
              <h2 class="heading text-center">JUDGE REGISTRATION FORM</h2>
            </div>
          </div>
        </div>

        <FlashMessage></FlashMessage>

        <div class="col-sm-12">
          <div
            v-if="errors && Object.keys(errors).length > 0"
            style="min-height: 20px; background: rgb(255 220 220)"
          >
            <div class="error p-5 text-center">
              <span
                v-for="(err, errIndex) in errors"
                :key="errIndex"
                class="text-danger"
              >
                {{ err[0] }} <br />
              </span>
            </div>
          </div>
        </div>

        <div class="form-group col-lg-6 col-sm-6">
          <label for="" class="req">Name</label>
          <input
            name="name"
            class="form-control"
            placeholder="Your Name"
            type="text"
            v-model="data.name"
            :class="{
              'is-invalid': validation.hasError('data.name'),
              'is-valid': data.name,
            }"
          />
          <small class="help-block">{{
            validation.firstError("data.name")
          }}</small>
        </div>
        <div class="form-group col-lg-6 col-sm-6">
          <label for="" class="req">Designation</label>
          <input
            name="designation"
            class="form-control"
            placeholder="Your Designation"
            type="text"
            v-model="data.designation"
            :class="{
              'is-invalid': validation.hasError('data.designation'),
              'is-valid': data.designation,
            }"
          />
          <small class="help-block">{{
            validation.firstError("data.designation")
          }}</small>
        </div>
        <div class="form-group col-lg-6 col-sm-6">
          <label for="" class="req">Company / Organization</label>
          <input
            name="company"
            class="form-control"
            placeholder="Your Company / Organization"
            type="text"
            v-model="data.company"
            :class="{
              'is-invalid': validation.hasError('data.company'),
              'is-valid': data.company,
            }"
          />
          <small class="help-block">{{
            validation.firstError("data.company")
          }}</small>
        </div>
        <div class="form-group col-lg-6 col-sm-6">
          <label for="" class="req">Mobile</label>
          <input
            name="mobile"
            class="form-control"
            placeholder="Your Mobile"
            type="text"
            v-model="data.mobile"
            :class="{
              'is-invalid': validation.hasError('data.mobile'),
              'is-valid': data.mobile,
            }"
          />
          <small class="help-block">{{
            validation.firstError("data.mobile")
          }}</small>
        </div>
        <div class="form-group col-lg-6 col-sm-6">
          <label for="" class="req">Email</label>
          <input
            name="email"
            class="form-control"
            placeholder="Your E-mail"
            type="text"
            v-model="data.email"
            :class="{
              'is-invalid': validation.hasError('data.email'),
              'is-valid': data.email,
            }"
          />
          <small class="help-block">{{
            validation.firstError("data.email")
          }}</small>
        </div>
        <div class="form-group form-logo mb-5 col-lg-6 col-sm-6">
          <label for="" class="req">Photo</label>
          <b-form-file
            accept="image/*"
            name="image"
            id="file-small"
            size="sm"
            class="file1"
            drop-placeholder="Drop file here"
            v-model="data.image"
            :class="{
              'is-invalid': validation.hasError('data.email'),
              'is-valid': data.email,
            }"
          ></b-form-file>
          <small class="help-block mt-4">{{
            validation.firstError("data.image")
          }}</small>
        </div>

        <div class="form-group col-lg-6 col-sm-6">
          <label for=""
            >Have you judged in previous BASIS National ICT Awards
            before?</label
          >
          <div class="row">
            <div class="col-lg-3 col-sm-12">
              <input
                v-model="data.prev_judge"
                type="radio"
                value="1"
                name="prev_judge"
                id="prev_judge"
              />
              &nbsp;Yes
            </div>
            <div class="col-lg-3 col-sm-12">
              <input
                v-model="data.prev_judge"
                type="radio"
                value="0"
                name="prev_judge"
                id="prev_judge"
              />
              &nbsp;No
            </div>
          </div>
          <small class="help-block">{{
            validation.firstError("data.prev_judge")
          }}</small>
        </div>

        <div class="form-group mb-5 col-lg-6 col-sm-6">
          <label for="">Address</label>
          <textarea
            name="address"
            v-model="data.address"
            class="form-control form-control-sm"
            style="height: 40px"
          ></textarea>
          <small class="help-block">{{
            validation.firstError("data.address")
          }}</small>
        </div>
        <div class="form-group col-lg-6 col-sm-6">
          <label for="">Professional Qualification</label>
          <textarea
            name="professional_qualification"
            v-model="data.professional_qualification"
            class="form-control"
            style="height: 40px"
          ></textarea>
          <small class="help-block">{{
            validation.firstError("data.professional_qualification")
          }}</small>
        </div>
        <div class="form-group col-lg-6 col-sm-6">
          <label for="">Working Experience</label>
          <textarea
            name="working_experience"
            v-model="data.working_experience"
            class="form-control"
            style="height: 40px"
          ></textarea>
          <small class="help-block">{{
            validation.firstError("data.working_experience")
          }}</small>
        </div>

        <div class="form-group col-lg-6 col-sm-6">
          <label for="" class="">Linkdin Profile</label>
          <input name="linkedin_profile" class="form-control" placeholder="Your linkdin profile" type="url"
            v-model="data.linkedin_profile" :class="{
                                    'is-invalid': validation.hasError('data.linkedin_profile'),
                                    'is-valid': data.linkedin_profile,
                                  }" />
          <small class="help-block">{{
            validation.firstError("data.linkedin_profile")
            }}</small>
        </div>

      </div>

      <!-- SUB CATEGORY -->
      <!-- <div class="col-sm-12 col-lg-12 mt-5 d-none">
        <div class="form-row">
          <div class="col-sm-12">
            <h3 class="mb-3 heading text-center">
              Which Sub-Categories you are interested for judging ?
            </h3>
          </div>

          <slot v-if="categories && Object.keys(categories).length > 0">
            <div
              class="col-lg-6 col-sm-12"
              v-for="(name, id, index) in categories"
              :key="index"
            >
              <input
                v-model="data.sub_category[id]"
                type="checkbox"
                :value="id"
                :name="'sub_category[' + index + '][category_id]'"
              />
              &nbsp;{{ name }}
            </div>
          </slot>
        </div>
      </div> -->

      <div class="col-sm-12 col-lg-3 col-lg-offset-4 mt-5">
        <button
          type="button"
          @click="submit()"
          :disabled="reg_spinner?true:false"
          class="btn btn-success btn-block mt-5 lgx-btn"
          style="background: #ec398b; border: none"
        >
          <span v-if="!reg_spinner">SUBMIT</span>
          <span v-else>
            <i class="fa fa-spinner fa-spin"></i> processing...
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reg_spinner: false,
      categories: JSON.parse(this.categories_obj),
      data: { sub_category: {}, prev_judge: "" },
      errors: {},
    };
  },
  methods: {
    submit: function () {
      if (this.reg_spinner) {
        return false;
      }
      this.$validate().then((res) => {
        const error = this.validation.countErrors();
        // If there is an error
        if (error > 0) {
          this.notification(
            "You need to fill " + error + " more empty mandatory fields",
            "warning"
          );
          this.scrollTop(0, 230);
          console.log(this.validation.allErrors());
          return false;
        }

        if (res) {
          var form = document.getElementById("form");
          var formData = new FormData(form);

          this.reg_spinner = true;
          axios
            .post("/awards/judge-store", formData)
            .then((res) => {
              this.data = {};
              this.notification("Registration Successfully", "success");
              window.location.href = "/registration-success";
            })
            .catch((error) => {
              if (error.response.status === 422) {
                this.scrollTop(0, 230);
                this.errors = error.response.data.errors || {};
                this.notification(
                  "Something went wrong, please try again",
                  "error"
                );
              }
            })
            .then((alw) => setTimeout(() => (this.reg_spinner = false), 200));
        }
      });
    },
  },
  props: {
    categories_obj: String,
  },
  validators: {
    "data.name": function (value = null) {
      return Validator.value(value).required("Name is required");
    },
    "data.email": function (value = null) {
      return Validator.value(value).required("Email is required").email();
    },
    "data.designation": function (value = null) {
      return Validator.value(value).required("Designation is required");
    },
    "data.company": function (value = null) {
      return Validator.value(value).required("Company is required");
    },
    "data.mobile": function (value = null) {
      return Validator.value(value)
        .required("This is required")
        .regex("01+[0-9+-]*$", "Must start with 01.")
        .minLength(11)
        .maxLength(11);
    },
    "data.image": function (value = null) {
      return Validator.value(value)
        .required("Photo is required")
        .custom(function () {
          if (!Validator.isEmpty(value)) {
            var type = value.type;
            if (
              type == "image/jpeg" ||
              type == "image/jpg" ||
              type == "image/png"
            ) {
            } else {
              return "Photo must be of type .jpeg  .jpg or .png";
            }
          }
        });
    },
  },
};
</script>
