var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"col-sm-12 text-center"},[_c('b-form-checkbox',{staticClass:"col-form-label-smx two-category",attrs:{"name":"technology_category","value":1,"unchecked-value":0},model:{value:(_vm.$parent.data.technology_category),callback:function ($$v) {_vm.$set(_vm.$parent.data, "technology_category", $$v)},expression:"$parent.data.technology_category"}},[_vm._v("\n        Technology Category")]),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.technology_category")))]),_vm._v(" "),_c('hr')],1),_vm._v(" "),(_vm.$parent.data.technology_category)?_vm._t("default",function(){return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("Sub Category")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.technology_sub_category),expression:"$parent.data.technology_sub_category"}],staticClass:"form-control",attrs:{"name":"technology_sub_category"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$parent.data, "technology_sub_category", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("--Select One--")]),_vm._v(" "),(
            _vm.$parent.technology_categories &&
            Object.keys(_vm.$parent.technology_categories).length > 0
          )?_vm._t("default",function(){return _vm._l((_vm.$parent.technology_categories),function(tech,id){return _c('option',{key:id,domProps:{"value":id}},[_vm._v("\n            "+_vm._s(tech)+"\n          ")])})}):_vm._e()],2)]),_vm._v(" "),(_vm.$parent.data.technology_sub_category == 44)?_vm._t("default",function(){return [_c('div',{staticClass:"form-group mt-5"},[_vm._m(0),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.technology_blockchain_description.value_to_society
          ),expression:"\n            $parent.data.technology_blockchain_description.value_to_society\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.technology_blockchain_description.value_to_society'
            ),
            'is-valid':
              _vm.$parent.data.technology_blockchain_description
                .value_to_society &&
              !_vm.$parent.validation.hasError(
                'data.technology_blockchain_description.value_to_society'
              ),
          },attrs:{"name":"technology_blockchain_description[value_to_society]"},domProps:{"value":(
            _vm.$parent.data.technology_blockchain_description.value_to_society
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.technology_blockchain_description, "value_to_society", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(1),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.technology_blockchain_description.market_potential
          ),expression:"\n            $parent.data.technology_blockchain_description.market_potential\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.technology_blockchain_description.market_potential'
            ),
            'is-valid':
              _vm.$parent.data.technology_blockchain_description
                .market_potential &&
              !_vm.$parent.validation.hasError(
                'data.technology_blockchain_description.market_potential'
              ),
          },attrs:{"name":"technology_blockchain_description[market_potential]"},domProps:{"value":(
            _vm.$parent.data.technology_blockchain_description.market_potential
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.technology_blockchain_description, "market_potential", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(2),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.technology_blockchain_description
              .functionality_features
          ),expression:"\n            $parent.data.technology_blockchain_description\n              .functionality_features\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.technology_blockchain_description.functionality_features'
            ),
            'is-valid':
              _vm.$parent.data.technology_blockchain_description
                .functionality_features &&
              !_vm.$parent.validation.hasError(
                'data.technology_blockchain_description.functionality_features'
              ),
          },attrs:{"name":"technology_blockchain_description[functionality_features]"},domProps:{"value":(
            _vm.$parent.data.technology_blockchain_description
              .functionality_features
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.technology_blockchain_description
              , "functionality_features", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(3),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.technology_blockchain_description
              .openness_integration
          ),expression:"\n            $parent.data.technology_blockchain_description\n              .openness_integration\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.technology_blockchain_description.openness_integration'
            ),
            'is-valid':
              _vm.$parent.data.technology_blockchain_description
                .openness_integration &&
              !_vm.$parent.validation.hasError(
                'data.technology_blockchain_description.openness_integration'
              ),
          },attrs:{"name":"technology_blockchain_description[openness_integration]"},domProps:{"value":(
            _vm.$parent.data.technology_blockchain_description
              .openness_integration
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.technology_blockchain_description
              , "openness_integration", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(4),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.technology_blockchain_description
              .quality_application_of_technology
          ),expression:"\n            $parent.data.technology_blockchain_description\n              .quality_application_of_technology\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.technology_blockchain_description.quality_application_of_technology'
            ),
            'is-valid':
              _vm.$parent.data.technology_blockchain_description
                .quality_application_of_technology &&
              !_vm.$parent.validation.hasError(
                'data.technology_blockchain_description.quality_application_of_technology'
              ),
          },attrs:{"name":"technology_blockchain_description[quality_application_of_technology]"},domProps:{"value":(
            _vm.$parent.data.technology_blockchain_description
              .quality_application_of_technology
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.technology_blockchain_description
              , "quality_application_of_technology", $event.target.value)}}})])]}):_vm._e(),_vm._v(" "),(
        _vm.$parent.data.technology_sub_category &&
        _vm.$parent.data.technology_sub_category != 44
      )?_vm._t("default",function(){return [_c('div',{staticClass:"form-group mt-5"},[_vm._m(5),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.technology_bigdata_description.security),expression:"$parent.data.technology_bigdata_description.security"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.technology_bigdata_description.security'
            ),
            'is-valid':
              _vm.$parent.data.technology_bigdata_description.security &&
              !_vm.$parent.validation.hasError(
                'data.technology_bigdata_description.security'
              ),
          },attrs:{"name":"technology_bigdata_description[security]"},domProps:{"value":(_vm.$parent.data.technology_bigdata_description.security)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.technology_bigdata_description, "security", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(6),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.technology_bigdata_description.creativity),expression:"$parent.data.technology_bigdata_description.creativity"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.technology_bigdata_description.creativity'
            ),
            'is-valid':
              _vm.$parent.data.technology_bigdata_description.creativity &&
              !_vm.$parent.validation.hasError(
                'data.technology_bigdata_description.creativity'
              ),
          },attrs:{"name":"technology_bigdata_description[creativity]"},domProps:{"value":(_vm.$parent.data.technology_bigdata_description.creativity)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.technology_bigdata_description, "creativity", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(7),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.technology_bigdata_description.scalability),expression:"$parent.data.technology_bigdata_description.scalability"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.technology_bigdata_description.scalability'
            ),
            'is-valid':
              _vm.$parent.data.technology_bigdata_description.scalability &&
              !_vm.$parent.validation.hasError(
                'data.technology_bigdata_description.scalability'
              ),
          },attrs:{"name":"technology_bigdata_description[scalability]"},domProps:{"value":(_vm.$parent.data.technology_bigdata_description.scalability)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.technology_bigdata_description, "scalability", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(8),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.technology_bigdata_description.openness_integration
          ),expression:"\n            $parent.data.technology_bigdata_description.openness_integration\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.technology_bigdata_description.openness_integration'
            ),
            'is-valid':
              _vm.$parent.data.technology_bigdata_description
                .openness_integration &&
              !_vm.$parent.validation.hasError(
                'data.technology_bigdata_description.openness_integration'
              ),
          },attrs:{"name":"technology_bigdata_description[openness_integration]"},domProps:{"value":(
            _vm.$parent.data.technology_bigdata_description.openness_integration
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.technology_bigdata_description, "openness_integration", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(9),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.technology_bigdata_description
              .quality_application_of_technology
          ),expression:"\n            $parent.data.technology_bigdata_description\n              .quality_application_of_technology\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.technology_bigdata_description.quality_application_of_technology'
            ),
            'is-valid':
              _vm.$parent.data.technology_bigdata_description
                .quality_application_of_technology &&
              !_vm.$parent.validation.hasError(
                'data.technology_bigdata_description.quality_application_of_technology'
              ),
          },attrs:{"name":"technology_bigdata_description[quality_application_of_technology]"},domProps:{"value":(
            _vm.$parent.data.technology_bigdata_description
              .quality_application_of_technology
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.technology_bigdata_description
              , "quality_application_of_technology", $event.target.value)}}})])]}):_vm._e()]}):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Value to Society")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description indicating costs saving,\n            enhanced security or transparency etc.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Market Potential")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description indicating market share &\n            potential, Business and functional models/strategies adopted etc.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Functionality & Features")]),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description indicating user requirements,\n            compatibility and interoperability features.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Openness/ Integration")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description on compliance to industry\n            standards, integration of 3rd party solutions to enhance and/or\n            monitor etc.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Quality / Application of Technology")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Describe the technologies deployed in product\n            development; Standards adopted/maintained/adhered (CMM/ISO etc) in\n            product development. QA activities carried out in product\n            development. Awards won locally, regionally and internationally by\n            the product (if applicable). Stability and reliability of product\n            (from end user feedback). Description of external packaging of the\n            product. Documentation and contents available for the product.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Security")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description indicating Solution\n            Architecture, Platform design, compliance and adherence to\n            security standards.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Creativity")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description of Processes used, Ease of use,\n            efficiencies, advantages, learning capabilities etc. of the\n            product.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Scalability")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description indicating solutions\n            scalability, ease of deployment, productivity and integration for\n            business use.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Openness/ Integration")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description on compliance to industry\n            standards, integration of 3rd party solutions to enhance and/or\n            monitor etc.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Quality / Application of Technology ")]),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Describe the technologies deployed in product\n            development; Standards adopted/maintained/adhered (CMM/ISO etc) in\n            product development. QA activities carried out in product\n            development. Awards won locally, regionally and internationally by\n            the product (if applicable). Stability and reliability of product\n            (from end user feedback). Description of external packaging of the\n            product. Documentation and contents available for the product.\n          ")])])
}]

export { render, staticRenderFns }