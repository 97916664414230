var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"col-sm-12 text-center"},[_c('b-form-checkbox',{staticClass:"col-form-label-smx two-category",attrs:{"name":"cross_category","value":1,"unchecked-value":0},model:{value:(_vm.$parent.data.cross_category),callback:function ($$v) {_vm.$set(_vm.$parent.data, "cross_category", $$v)},expression:"$parent.data.cross_category"}},[_vm._v("\n        Cross Category")]),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.cross_category")))]),_vm._v(" "),_c('hr')],1),_vm._v(" "),(_vm.$parent.data.cross_category)?_vm._t("default",function(){return [_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"req"},[_vm._v("Sub Category")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.cross_sub_category),expression:"$parent.data.cross_sub_category"}],staticClass:"form-control",attrs:{"name":"cross_sub_category"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$parent.data, "cross_sub_category", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("--Select One--")]),_vm._v(" "),(
            _vm.$parent.cross_categories &&
            Object.keys(_vm.$parent.cross_categories).length > 0
          )?_vm._t("default",function(){return _vm._l((_vm.$parent.cross_categories),function(cross,id){return _c('option',{key:id,domProps:{"value":id}},[_vm._v("\n            "+_vm._s(cross)+"\n          ")])})}):_vm._e()],2),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError("data.cross_sub_category")))])]),_vm._v(" "),(_vm.$parent.data.cross_sub_category == 39)?_vm._t("default",function(){return [_c('div',{staticClass:"form-group mt-5"},[_vm._m(0),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.cross_startup_description.business_model_financials
          ),expression:"\n            $parent.data.cross_startup_description.business_model_financials\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.cross_startup_description.business_model_financials'
            ),
            'is-valid':
              _vm.$parent.data.cross_startup_description
                .business_model_financials &&
              !_vm.$parent.validation.hasError(
                'data.cross_startup_description.business_model_financials'
              ),
          },attrs:{"name":"cross_startup_description[business_model_financials]"},domProps:{"value":(
            _vm.$parent.data.cross_startup_description.business_model_financials
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.cross_startup_description, "business_model_financials", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
            "data.cross_startup_description.business_model_financials"
          )))])]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(1),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.cross_startup_description.clientreach),expression:"$parent.data.cross_startup_description.clientreach"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.cross_startup_description.clientreach'
            ),
            'is-valid':
              _vm.$parent.data.cross_startup_description.clientreach &&
              !_vm.$parent.validation.hasError(
                'data.cross_startup_description.clientreach'
              ),
          },attrs:{"name":"cross_startup_description[clientreach]"},domProps:{"value":(_vm.$parent.data.cross_startup_description.clientreach)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.cross_startup_description, "clientreach", $event.target.value)}}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
            "data.cross_startup_description.clientreach"
          )))])]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(2),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.cross_startup_description.execution),expression:"$parent.data.cross_startup_description.execution"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.cross_startup_description.execution'
            ),
            'is-valid':
              _vm.$parent.data.cross_startup_description.execution &&
              !_vm.$parent.validation.hasError(
                'data.cross_startup_description.execution'
              ),
          },attrs:{"name":"cross_startup_description[execution]"},domProps:{"value":(_vm.$parent.data.cross_startup_description.execution)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.cross_startup_description, "execution", $event.target.value)}}}),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
            "data.cross_startup_description.execution"
          )))])]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(3),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.cross_startup_description.investment_viability
          ),expression:"\n            $parent.data.cross_startup_description.investment_viability\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.cross_startup_description.investment_viability'
            ),
            'is-valid':
              _vm.$parent.data.cross_startup_description.investment_viability &&
              !_vm.$parent.validation.hasError(
                'data.cross_startup_description.investment_viability'
              ),
          },attrs:{"name":"cross_startup_description[investment_viability]"},domProps:{"value":(
            _vm.$parent.data.cross_startup_description.investment_viability
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.cross_startup_description, "investment_viability", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
            "data.cross_startup_description.investment_viability"
          )))])]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(4),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.cross_startup_description.unique_selling_proposition
          ),expression:"\n            $parent.data.cross_startup_description.unique_selling_proposition\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.cross_startup_description.unique_selling_proposition'
            ),
            'is-valid':
              _vm.$parent.data.cross_startup_description
                .unique_selling_proposition &&
              !_vm.$parent.validation.hasError(
                'data.cross_startup_description.unique_selling_proposition'
              ),
          },attrs:{"name":"cross_startup_description[unique_selling_proposition]"},domProps:{"value":(
            _vm.$parent.data.cross_startup_description.unique_selling_proposition
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.cross_startup_description, "unique_selling_proposition", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
            "data.cross_startup_description.unique_selling_proposition"
          )))])]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(5),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.cross_startup_description
              .quality_application_of_technology
          ),expression:"\n            $parent.data.cross_startup_description\n              .quality_application_of_technology\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.cross_startup_description.quality_application_of_technology'
            ),
            'is-valid':
              _vm.$parent.data.cross_startup_description
                .quality_application_of_technology &&
              !_vm.$parent.validation.hasError(
                'data.cross_startup_description.quality_application_of_technology'
              ),
          },attrs:{"name":"cross_startup_description[quality_application_of_technology]"},domProps:{"value":(
            _vm.$parent.data.cross_startup_description
              .quality_application_of_technology
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.cross_startup_description
              , "quality_application_of_technology", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
            "data.cross_startup_description.quality_application_of_technology"
          )))])])]}):_vm._e(),_vm._v(" "),(_vm.$parent.data.cross_sub_category == 40)?_vm._t("default",function(){return [_c('div',{staticClass:"form-group mt-5"},[_vm._m(6),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.cross_research_description.uniqueness),expression:"$parent.data.cross_research_description.uniqueness"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.cross_research_description.uniqueness'
            ),
            'is-valid':
              _vm.$parent.data.cross_research_description.uniqueness &&
              !_vm.$parent.validation.hasError(
                'data.cross_research_description.uniqueness'
              ),
          },attrs:{"name":"cross_research_description[uniqueness]"},domProps:{"value":(_vm.$parent.data.cross_research_description.uniqueness)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.cross_research_description, "uniqueness", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
            "data.cross_research_description.uniqueness"
          )))])]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(7),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.$parent.data.cross_research_description.proof_concept),expression:"$parent.data.cross_research_description.proof_concept"}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.cross_research_description.proof_concept'
            ),
            'is-valid':
              _vm.$parent.data.cross_research_description.proof_concept &&
              !_vm.$parent.validation.hasError(
                'data.cross_research_description.proof_concept'
              ),
          },attrs:{"name":"cross_research_description[proof_concept]"},domProps:{"value":(_vm.$parent.data.cross_research_description.proof_concept)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.cross_research_description, "proof_concept", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
            "data.cross_research_description.proof_concept"
          )))])]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(8),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.cross_research_description.functionality_features
          ),expression:"\n            $parent.data.cross_research_description.functionality_features\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.cross_research_description.functionality_features'
            ),
            'is-valid':
              _vm.$parent.data.cross_research_description
                .functionality_features &&
              !_vm.$parent.validation.hasError(
                'data.cross_research_description.functionality_features'
              ),
          },attrs:{"name":"cross_research_description[functionality_features]"},domProps:{"value":(
            _vm.$parent.data.cross_research_description.functionality_features
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.cross_research_description, "functionality_features", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
            "data.cross_research_description.functionality_features"
          )))])]),_vm._v(" "),_c('div',{staticClass:"form-group mt-5"},[_vm._m(9),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.$parent.data.cross_research_description
              .quality_application_of_technology
          ),expression:"\n            $parent.data.cross_research_description\n              .quality_application_of_technology\n          "}],staticClass:"form-control",class:{
            'is-invalid': _vm.$parent.validation.hasError(
              'data.cross_research_description.quality_application_of_technology'
            ),
            'is-valid':
              _vm.$parent.data.cross_research_description
                .quality_application_of_technology &&
              !_vm.$parent.validation.hasError(
                'data.cross_research_description.quality_application_of_technology'
              ),
          },attrs:{"name":"cross_research_description[quality_application_of_technology]"},domProps:{"value":(
            _vm.$parent.data.cross_research_description
              .quality_application_of_technology
          )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$parent.data.cross_research_description
              , "quality_application_of_technology", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"has-error"},[_vm._v(_vm._s(_vm.$parent.validation.firstError(
            "data.cross_research_description.quality_application_of_technology"
          )))])])]}):_vm._e()]}):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Business Model and Financials")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description including funding and\n            scalability of the operations etc.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Client Reach")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description including the quality/type of\n            clients, satisfaction levels etc.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Execution – Team Composition – Implementation")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description of the organization indicating\n            scalability, team composition etc.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Investment Viability")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description including funding and\n            scalability of the operations etc.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Unique Selling Proposition")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Brief description indicating competitive\n            advantages, differentiation, Market entry barriers, IPR etc.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Quality / Application of Technology")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Describe the technologies deployed in product\n            development; Standards adopted/maintained/adhered (CMM/ISO etc) in\n            product development. QA activities carried out in product\n            development. Awards won locally, regionally and internationally by\n            the product (if applicable). Stability and reliability of product\n            (from end user feedback). Description of external packaging of the\n            product. Documentation and contents available for the product.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Uniqueness")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Describe the uniqueness including Technologies &\n            innovations used to develop the product. Specific problems solved\n            by the product in the environment. Innovative functions and\n            features in the product. Competitive advantages over similar\n            products in the local, Asia Pacific & international markets.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Proof of Concept")]),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Briefly describe the commercial potential, pilot\n            trials carried out, results and outcomes etc.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Functionality & Features")]),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Describe the Features and Functionalities with\n            Targeted users of the product and user requirements covered by the\n            product. Scalability and expandability of the product. User\n            friendliness and Compatibility with interoperability with the\n            other systems in the market. Security features in the product.\n            Features/functions in the product that addresses / relates to\n            product maintenance.\n          ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',[_c('span',{staticClass:"req"},[_vm._v("Quality / Application of Technology ")]),_c('br'),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v("\n            (Max 350 Words) Describe the technologies deployed in product\n            development; Standards adopted/maintained/adhered (CMM/ISO etc) in\n            product development. QA activities carried out in product\n            development. Awards won locally, regionally and internationally by\n            the product (if applicable). Stability and reliability of product\n            (from end user feedback). Description of external packaging of the\n            product. Documentation and contents available for the product.\n          ")])])
}]

export { render, staticRenderFns }