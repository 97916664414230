require('./frontend_bootstrap');
window.Vue = require('vue');
require('./Helpers/filter');
require('./Helpers/Frontend/crud');
require('./Helpers/Frontend/mixin');
require('./Helpers/Frontend/plugin');

Vue.component('apply-form', require('./views/frontend/ApplyForm.vue').default);
Vue.component('contact-form', require('./views/frontend/ContactForm.vue').default);
Vue.component('judge-registration', require('./views/frontend/JudgeRegistration.vue').default);
Vue.component('success', require('./views/frontend/message/Success.vue').default);
Vue.component('draft', require('./views/frontend/message/Draft.vue').default);
Vue.component('error', require('./views/frontend/message/Error.vue').default);
Vue.component('register-success', require('./views/frontend/message/JudgeRegistrationSuccess.vue').default);

// ===============app===============
window.app = new Vue({
    el: '#app',
    data: {
        baseurl: laravel.baseurl,
        asset: laravel.baseurl + '/public',
        loader: false,
        spinner: false,
        isActive: false,
    },
    methods: {},
    mounted() { },
    created() { }
});
