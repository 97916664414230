<template>
  <div>
    <slot>
      <!-- <FlashMessage></FlashMessage> -->
    </slot>
    <div class="form-group">
      <input
        name="name"
        class="form-control inputBox"
        placeholder="Your Name ..."
        type="text"
        v-model="data.name"
        :class="{
          'is-invalid': validation.hasError('data.name'),
          'is-valid': data.name,
        }"
      />
    </div>
    <div class="form-group">
      <input
        name="email"
        class="form-control inputBox"
        placeholder="Your E-mail ..."
        type="email"
        v-model="data.email"
        :class="{
          'is-invalid': validation.hasError('data.email'),
          'is-valid': data.email,
        }"
      />
    </div>
    <div class="form-group">
      <textarea
        name="message"
        v-model="data.message"
        style="color: #d4dde6"
        class="form-control texareaBox"
        placeholder="Write your message here.."
        :class="{
          'is-invalid': validation.hasError('data.message'),
          'is-valid': data.message,
        }"
      ></textarea>
    </div>
    <button
      type="button"
      @click="submit()"
      class="btn btn-success btn-block website-query"
      style="background: #2260b0; border: none"
    >
      <span v-if="!spinner">SEND</span>
      <span v-else> <i class="fa fa-spinner fa-spin"></i> sending... </span>
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinner: false,
      data: {},
    };
  },
  methods: {
    submit: function () {
      this.$validate().then((res) => {
        if (res) {
          this.spinner = true;
          axios
            .post("/feedback-send", this.data)
            .then((res) => {
              this.data = {};
              alert("Feedback send successfully");
              this.notification("Feedback send successfully", "success");
            })
            .catch((error) => console.log())
            .then((alw) => setTimeout(() => (this.spinner = false), 200));
        }
      });
    },
  },
  validators: {
    "data.name": function (value = null) {
      return Validator.value(value).required("This is required");
    },
    "data.email": function (value = null) {
      return Validator.value(value).required("This is required");
    },
    "data.message": function (value = null) {
      return Validator.value(value).required("This is required");
    },
  },
};
</script>
